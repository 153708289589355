import React, { Component } from "react";
import "./header.css";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      menuOpen: false,
    };
  }

  componentDidMount() {
    // Add scroll event listener
    window.addEventListener("scroll", this.handleScroll);

    // Check if there's a section to scroll to from local storage
    const sectionToScroll = localStorage.getItem("scrollToSection");
    if (sectionToScroll) {
      const element = document.querySelector(`.${sectionToScroll}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      // Clear the stored section after scrolling
      localStorage.removeItem("scrollToSection");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 0 && !this.state.scrolled) {
      this.setState({ scrolled: true });
    } else if (window.scrollY === 0 && this.state.scrolled) {
      this.setState({ scrolled: false });
    }
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  scrollToSection = (sectionClass) => {
    if (window.location.pathname !== "/") {
      window.location.replace("/");
      localStorage.setItem("scrollToSection", sectionClass);
    } else {
      const element = document.querySelector(`.${sectionClass}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  render() {
    return (
      <div className={`header ${this.state.scrolled ? "scrolled" : ""}`}>
        <div className="inner_header">
          <div className="logo_container">
            <a href="/">
              <img className="logo" src="/Images/Logo.png" alt="Logo" />
            </a>
          </div>
          <div className="menu_toggle" onClick={this.toggleMenu}>
            <div className="menu_icon"></div>
            <div className="menu_icon"></div>
            <div className="menu_icon"></div>
          </div>
          <ul className={`navigation ${this.state.menuOpen ? "open" : ""}`}>
            <li>
              <a href="/">Accueil</a>
            </li>
            <li>
              <a className="main-link">Nos services</a>
              <div className="dropdown">
                <div className="dropdown-content">
                  <a href="#!" onClick={() => this.scrollToSection("WebI1")}>
                    Web Design
                  </a>
                  <a href="#!" onClick={() => this.scrollToSection("WebI2")}>
                    Web Development
                  </a>
                  <a href="#!" onClick={() => this.scrollToSection("WebI3")}>
                    Hébergement
                  </a>
                </div>
              </div>
            </li>
            <li>
              <a href="/Contact">Contact</a>
            </li>
            <li>
              <a className="Devis" href="/Contact">
                Commencer
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

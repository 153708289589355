import React, { useEffect, useState, useRef } from "react";
import "./Follow.css";

const Follow = () => {
  const [dotPosition, setDotPosition] = useState({ x: -50, y: -50 });
  const [ringPosition, setRingPosition] = useState({ x: -50, y: -50 });
  const [isHovering, setIsHovering] = useState(false);
  const mousePos = useRef({ x: -50, y: -50 });
  const ringPos = useRef({ x: -50, y: -50 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      mousePos.current = { x: e.clientX, y: e.clientY };
      const target = e.target;

      // Check if hovering over a button or a link
      if (
        target.tagName === "BUTTON" ||
        target.tagName === "A" ||
        target.tagName === "I"
      ) {
        setIsHovering(true);
      } else {
        setIsHovering(false);
      }
    };

    const animate = () => {
      const { x: mouseX, y: mouseY } = mousePos.current;

      // Update dot position immediately
      setDotPosition({ x: mouseX, y: mouseY });

      // Easing factor for smooth trailing effect on the ring
      const easeFactor = 0.1;
      ringPos.current = {
        x: ringPos.current.x + (mouseX - ringPos.current.x) * easeFactor,
        y: ringPos.current.y + (mouseY - ringPos.current.y) * easeFactor,
      };

      setRingPosition({ x: ringPos.current.x, y: ringPos.current.y });

      // Continue the animation loop
      requestAnimationFrame(animate);
    };

    // Start the animation loop
    requestAnimationFrame(animate);

    // Event listener for mouse movements
    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div>
      <div
        className={`dot ${isHovering ? "hover" : ""}`}
        style={{ left: `${dotPosition.x}px`, top: `${dotPosition.y}px` }}
      ></div>
      <div
        className={`ring ${isHovering ? "hover" : ""}`}
        style={{ left: `${ringPosition.x}px`, top: `${ringPosition.y}px` }}
      ></div>
    </div>
  );
};

export default Follow;

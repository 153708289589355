import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import ReactGA from "react-ga4"; // Import react-ga4
import "./Contact.css";

emailjs.init("SzbBJIattT7M16ZLY");
ReactGA.initialize("G-NPW0GC5ZC6");

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    siteType: "personnel",
    businessType: "seul",
    existingSite: "non",
    projectDetails: "",
  });

  const formRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Track field click in Google Analytics with the class name as identifier
    ReactGA.event({
      category: "Form",
      action: "Field Interaction",
      label: `ContactForm - ${name}`, // Including the component name
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email) {
      alert("Nom et email sont obligatoires !");
      return;
    }

    // Track the form submission with Facebook Pixel
    if (typeof window.fbq !== "undefined") {
      window.fbq("track", "Lead", {
        content_name: "Contact Form Submission",
        value: 0,
        currency: "USD",
      });
    }

    emailjs
      .sendForm("service_4hsq451", "template_t3ko3na", formRef.current)
      .then(
        () => {
          alert("Votre message a été envoyé avec succès !");
          setFormData({
            name: "",
            email: "",
            phone: "",
            company: "",
            siteType: "personnel",
            businessType: "seul",
            existingSite: "non",
            projectDetails: "",
          });

          // Track the successful form submission in Google Analytics
          ReactGA.event({
            category: "Form",
            action: "Submission Successful",
            label: "Contact Form Submission",
          });
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Erreur lors de l'envoi, veuillez réessayer.");
        }
      );
  };

  useEffect(() => {
    const componentClassName = "ContactForm"; // Class name as unique identifier

    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname} - ${componentClassName}`, // Use class name for uniqueness
    });

    const trackScrollDepth = () => {
      const scrollDepth = Math.round(
        (window.scrollY /
          (document.documentElement.scrollHeight - window.innerHeight)) *
          100
      );
      ReactGA.event({
        category: "Scroll",
        action: "Scroll Depth",
        label: `${componentClassName} - ${window.location.pathname}`,
        value: scrollDepth,
      });
    };

    window.addEventListener("scroll", trackScrollDepth);

    return () => {
      window.removeEventListener("scroll", trackScrollDepth);
    };
  }, []);

  return (
    <div className="ContactWrapper">
      <form ref={formRef} className="ContactForm" onSubmit={sendEmail}>
        <h2>Contactez-nous</h2>

        <label>Nom et Prénom *</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label>Email *</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label>Numéro de téléphone</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />

        <label>Entreprise (Optionnel)</label>
        <input
          type="text"
          name="company"
          value={formData.company}
          onChange={handleChange}
        />

        <label>Quel type de site web avez-vous besoin ?</label>
        <select
          name="siteType"
          value={formData.siteType}
          onChange={handleChange}
        >
          <option value="personnel">Pour moi</option>
          <option value="entreprise">Pour mon entreprise</option>
        </select>

        <label>Pour quel type de business est-ce ?</label>
        <select
          name="businessType"
          value={formData.businessType}
          onChange={handleChange}
        >
          <option value="seul">Seul</option>
          <option value="petit">Petit business (0-9 employés)</option>
          <option value="moyen">Business moyen (10-29 employés)</option>
          <option value="grand">Grand business (30+ employés)</option>
        </select>

        <label>Avez-vous déjà un site web ?</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name="existingSite"
              value="oui"
              checked={formData.existingSite === "oui"}
              onChange={handleChange}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Oui
          </label>
          <label>
            <input
              type="radio"
              name="existingSite"
              value="non"
              checked={formData.existingSite === "non"}
              onChange={handleChange}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Non
          </label>
        </div>

        <label>Parlez-nous de votre projet</label>
        <textarea
          name="projectDetails"
          value={formData.projectDetails}
          onChange={handleChange}
          rows="5"
        />

        <button type="submit">Envoyer</button>
      </form>
      <div className="Image-FW"> </div>
    </div>
  );
}

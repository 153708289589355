import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4"; // Ensure you're using react-ga4
import "./Accueil.css";

ReactGA.initialize("G-NPW0GC5ZC6");

const Accueil = () => {
  const navigate = useNavigate();
  const [activeImage, setActiveImage] = useState(1);
  const [isOfferVisible, setIsOfferVisible] = useState(true);
  const componentClassName = "Accueil"; // Class name as a unique identifier

  // Track page view on component mount
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname} - ${componentClassName}`, // Include the class name for uniqueness
    });
  }, []);

  // Effect for toggling images
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImage((prevImage) => (prevImage === 1 ? 2 : 1));
    }, 3000);

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  const closeSpecialOffer = () => {
    setIsOfferVisible(false);

    // Track special offer close event
    ReactGA.event({
      category: "Special Offer",
      action: "Close Offer",
      label: componentClassName, // Using class name as label
    });
  };

  const handleContactClick = () => {
    navigate("/Contact");

    // Track contact button click event
    ReactGA.event({
      category: "Button",
      action: "Contact Click",
      label: `${componentClassName} - Contact Button`, // Using class name as label
    });
  };

  return (
    <div className="AMainWrapper">
      {isOfferVisible && (
        <a href="/Contact" className="Special-Offer" id="special-offer">
          <span>Offre Special: </span>&nbsp;Une reduction de 20% sur toutes nos
          offres{" "}
          <button className="close-btn" onClick={closeSpecialOffer}>
            X
          </button>
        </a>
      )}

      <div className="SloganWrapper">
        <div className={`Slogan1 ${activeImage === 1 ? "active" : ""}`}>
          Votre site web sur mesure, <a>personnalisé pour vous !</a>
        </div>
        <div className={`Slogan2 ${activeImage === 2 ? "active" : ""}`}>
          Propulsez votre buisiness<a> en ligne dès maintenant !</a>
        </div>

        <div className="Sous-Slogan">
          Depuis 5 ans, nous développons des interfaces web sur-mesure,
          esthétiques et sécurisées. En offrant la meilleure expérience
          utilisateur adaptée à votre métier, nous vous garantissons la
          performance de votre site.
        </div>

        <button
          className="NousContacter"
          onClick={handleContactClick}
          role="button"
        >
          Nous Contacter
        </button>
      </div>

      <img
        className={`Photo ${activeImage === 1 ? "active" : ""}`}
        src="/Images/BlueSercle.png"
        alt="Blue Circle"
      />
      <img
        className={`Photo ${activeImage === 2 ? "active" : ""}`}
        src="/Images/GreenSercle.png"
        alt="Green Circle"
      />

      <img
        className={`Acc1 Acc ${activeImage === 1 ? "active" : ""}`}
        src="/Images/acc2.png"
        alt="acc1"
      />
      <img
        className={`Acc2 Acc ${activeImage === 2 ? "active" : ""}`}
        src="/Images/acc1.png"
        alt="acc2"
      />
    </div>
  );
};

export default Accueil;

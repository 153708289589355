import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./Header/header";
import Accueil from "./Accueil/Accueil";
import Accueil2 from "./Accueil/Accueil2";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import Follow from "./Mouse Follow/Follow";

function App() {
  return (
    <div>
      <Follow />
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Accueil />
              <Accueil2 />
            </>
          }
        />

        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
